@use "sass:math";

$color-red: #F06957;
$color-green: #3EB55F;
$color-blue: #5b78b7;
$color-blue-dark: #4d6bb2;
$color-blue-light: #6b8ad7;
$color-white: #fff;
$margin-top-app: 20px;
$number-of-steps: 7;
$steps-margin: 3px;
$color-gray-light: #cac9c9;
$color-gray: #b3b3b3;

:root {
  --color-blue: #{$color-blue};
  --color-blue-dark: #{$color-blue-dark};
  --color-blue-light: #{$color-blue-light};
  --color-red: #{$color-red};
}

html, body, #___gatsby {
  margin: 0;
  padding: 0;

  min-width: 100%;
  width: 100%;
  max-width: 100%;

  min-height: 100%;
  font-family: "Roboto" !important;
}

a {
  width: 100%;
  text-decoration: none;
}

.welcome-screen {
  height: 100vh;

  img {
    width: 100%;
  }
}

.header {
  padding-block: 8px;
  position: relative;
  margin-block-end: 8px;

  &__logo {
    height: 100%;

    &__image {
      max-width: 100%;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.login-screen {
  margin-top: 60px;

  &__logo {
    margin: 20px 0 60px 0 !important;

    img {
      width: 100%;
    }
  }
}

.license-expired-screen {
  margin: 50px 0 !important;

  &__card {
    background-color: $color-red;
    color: $color-white;

    &__logo {
      margin: 30px 0 !important;
    }

    &__title {
    }

    &__text {
      text-align: center;
      margin: 15px 0 !important;
    }
  }

  &__button {
    margin-top: 50px !important;
  }
}

.user-status {
  margin: $margin-top-app 0 !important;

  &__card {
    border: none !important;
    box-shadow: none !important;

    &__logo {
      height: 100px;
      width: 100px;
      padding: 20px 17px;
      background-color: $color-green;
      border-radius: 100%;
      margin: 15px 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;

      &__blocked {
        padding: 20px;
        background-color: $color-red;
      }
    }

    &__title {
      color: $color-green;

      &__blocked {
        color: $color-red;
      }
    }

    &__text {
      text-align: center;
      margin: 25px 0 !important;
    }
  }

  &__button {
    margin-top: 50px !important;
  }
}

.user-blocked {
  margin: 50px 0 !important;

  &__card {
    border: none !important;
    box-shadow: none !important;

    &__logo {
      height: 100px;
      width: 100px;
      padding: 20px;
      background-color: $color-red;
      border-radius: 100%;
      margin: 15px 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__title {
      color: $color-red;
    }

    &__text {
      text-align: center;
      margin: 25px 0 !important;
    }
  }

  &__button {
    margin-top: 30px !important;
  }
}

.menu-button {
  background: linear-gradient(119deg, $color-blue-light 0%, $color-blue 100%);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  width: 100%;
  border: none !important;
  border-radius: 0 !important;
  height: 90px;
  cursor: pointer;
  color: $color-white;

  &--disabled {
    cursor: auto;
    box-shadow: none;
    background: linear-gradient(119deg, $color-gray-light 0%, $color-gray 100%);
    color: $color-gray;
  }

  &--disabled &__text {
    color: #e8e8e8;
  }

  &__text {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    width: 100%;
    text-align: center;
    color: $color-white;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;

    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }
}

@mixin wizardStepWidth($number-of-steps, $steps-margin) {
  width: calc((100% / #{$number-of-steps}) - ((#{$number-of-steps} - 1) * #{$steps-margin}));
  margin: 0 $steps-margin 12px $steps-margin;
}

.wizard-button {
  position: relative;
  transition: 0.3s;
  background-color: $color-blue;
  opacity: 0.5;
  height: 10px;
  @include wizardStepWidth($number-of-steps, $steps-margin);

  &--done {
    opacity: 1;
    background-color: $color-blue;
    color: $color-white;

    &__blob {
      z-index: 2;
      position: relative;
      font-weight: 500;
      color: $color-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      margin: 0 10px;
      border-radius: 50%;
      border: 2px solid $color-blue;
      background-color: $color-white;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
      transition: 0.4s;

      &--filled {
        background-color: $color-blue;
        color: $color-white;
      }

      &--current {
        transform: scale(1.4)
      }
    }
  }
}

.menu.menu {
  margin-block: 40px 20px;

  &__item {
    margin: 5px 0;
  }
}

.login-form {
  width: 100%;

  &__element {
    padding: 20px 0;
  }

  &__button {
    margin-top: 16px !important;
  }
}


.oasis-select {
  margin-bottom: 24px;

  .MuiInputBase-input.MuiOutlinedInput-input {
    padding-bottom: 14px;
    padding-top: 14px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  label + .MuiInputBase-root {
    margin-top: 25px;
    margin-bottom: 2px;
    color: #ACB3BF !important;
  }

  legend span {
    display: none !important;
  }

  .MuiInput-underline.Mui-error:after {
    transform: scaleX(0) !important;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(1px, 2px) scale(0.77);
  }

}

.form-helper-error {
  margin-left: 0 !important;
  border-bottom: 0 !important;
  color: #f44336 !important;
}

.oasis-text-field {
  min-height: 72px;

  label + & {
    margin-top: 25px;
    color: #ACB3BF;
  }

  .Mui-error {
    input {
      border: 1px solid #f44336;
    }
  }

  .MuiInput-underline.Mui-error:after {
    transform: scaleX(0) !important;
  }

  input {
    border-radius: 0;
    position: relative;
    border: 1px solid $color-blue;
    padding: 14px 12px;
    color: $color-blue;
    outline: $color-blue;
  }
}

.center-label {
  text-align: center;
  width: 130%;
}

%status-tooltip {
  position: absolute;
  top: 27px;
  right: 15px;
  padding: 6px 20px;
  text-align: center;
  border-radius: 50px 10px 50px 50px;
  color: $color-white;
  opacity: 0;
  transition: all .2s ease-in-out;
}

.status {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;

  &:hover .status__message {
    opacity: 1;
  }

  &--green {
    & .status__message {
      @extend %status-tooltip;
      background-color: $color-green;
    }

    .status__center {
      background-color: $color-green;
      animation: pulse 2s infinite;
    }
  }

  &--red {
    & .status__message {
      @extend %status-tooltip;
      background-color: $color-red;
    }

    .status__center {
      background-color: $color-red;
    }
  }

  &:hover:after {
    opacity: 1;
  }

  &__center {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 100%;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

.text-separator {
  margin: 40px 0 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
